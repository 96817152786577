export type TrackerActionKeys = keyof typeof TRACKER_ACTION

export type TrackerEventKeys = keyof typeof TRACKER_EVENTS

export const TRACKER_TYPE = {
  ICON: 'icon',
  BTN: 'btn',
  TEXT: 'text',
  TEXT_FIELD: 'textfield',
  TEXT_BAR: 'textbar',
  BAR: 'bar',
  PIC: 'pic',
  LIST: 'list',
  AD: 'ad',
  TITLE: 'title',
  TAB: 'tab',
  CONTENT: 'content',
  CARD: 'card',
  KEYBOARD: 'keyboard',
  SCREEN: 'screen',
  PROMPT: 'prompt',
  ALERT: 'alert',
  EXTERNAL_E_NOTIFICATION: 'externalenotification',
  SWITCH_BTN: 'switchbtn',
  TIP: 'tip',
} as const

export const TRACKER_ACTION = {
  SHOW: 'show',
  CLICK: 'click',
  SWIPE: 'swipe',
  TYPING: 'typing',
  SWIPEUP: 'swipeup',
  SWIPE_LEFT_RIGHT: 'swipeleftright',
  COLOR_CHANGE: 'colorchange',
} as const

export const TRACKER_MAPPING_DICTIONARY: Record<
  TrackerActionKeys,
  keyof HTMLElementEventMap
> = {
  SHOW: 'error',
  CLICK: 'click',
  SWIPE: 'touchmove',
  TYPING: 'keyup',
  SWIPEUP: 'touchmove',
  SWIPE_LEFT_RIGHT: 'touchmove',
  COLOR_CHANGE: 'change',
}

export const TRACKER_EVENTS = {
  bar_aibotname_click: 'bar_aibotname_click',
  bar_aibotname_typing: 'bar_aibotname_typing',
  bar_cardnumber_click: 'bar_cardnumber_click',
  bar_cardnumber_typing: 'bar_cardnumber_typing',
  bar_confirmnewpassword_click: 'bar_confirmnewpassword_click',
  bar_confirmnewpassword_typing: 'bar_confirmnewpassword_typing',
  bar_contactemail_click: 'bar_contactemail_click',
  bar_contactemail_typing: 'bar_contactemail_typing',
  bar_conversationstarters_click: 'bar_conversationstarters_click',
  bar_conversationstarters_typing: 'bar_conversationstarters_typing',
  bar_currentpassword_click: 'bar_currentpassword_click',
  bar_currentpassword_typing: 'bar_currentpassword_typing',
  bar_email_click: 'bar_email_click',
  bar_email_typing: 'bar_email_typing',
  bar_engagements_click: 'bar_engagements_click',
  bar_engagements_typing: 'bar_engagements_typing',
  bar_expirydate_click: 'bar_expirydate_click',
  bar_expirydate_typing: 'bar_expirydate_typing',
  bar_message_click: 'bar_message_click',
  bar_message_typing: 'bar_message_typing',
  bar_name_click: 'bar_name_click',
  bar_name_typing: 'bar_name_typing',
  bar_newpassword_click: 'bar_newpassword_click',
  bar_newpassword_typing: 'bar_newpassword_typing',
  bar_password_click: 'bar_password_click',
  bar_password_typing: 'bar_password_typing',
  bar_search_click: 'bar_search_click',
  bar_search_typing: 'bar_search_typing',
  bar_securitycode_click: 'bar_securitycode_click',
  bar_securitycode_typing: 'bar_securitycode_typing',
  bar_skilltype_click: 'bar_skilltype_click',
  bar_skilltype_typing: 'bar_skilltype_typing',
  bar_username_click: 'bar_username_click',
  bar_username_typing: 'bar_username_typing',
  btn_add_click: 'btn_add_click',
  btn_add_faqs_click: 'btn_add_faqs_click',
  btn_approve_click: 'btn_approve_click',
  btn_cancel_click: 'btn_cancel_click',
  btn_cancelplan_click: 'btn_cancelplan_click',
  btn_cancelplan_show: 'btn_cancelplan_show',
  btn_change_click: 'btn_change_click',
  btn_changepaymentmethod_click: 'btn_changepaymentmethod_click',
  btn_changepaymentmethod_show: 'btn_changepaymentmethod_show',
  btn_chatwith_click: 'btn_chatwith_click',
  btn_complete_click: 'btn_complete_click',
  btn_delete_click: 'btn_delete_click',
  btn_deletechathistory_click: 'btn_deletechathistory_click',
  btn_deletechathistory_colorchange: 'btn_deletechathistory_colorchange',
  btn_edit_click: 'btn_edit_click',
  btn_editpayment_click: 'btn_editpayment_click',
  btn_facebook_click: 'btn_facebook_click',
  btn_filter_click: 'btn_filter_click',
  btn_filter_swipe: 'btn_filter_swipe',
  btn_generate_click: 'btn_generate_click',
  btn_google_click: 'btn_google_click',
  btn_knowledge_upload_click: 'btn_knowledge_upload_click',
  btn_later_click: 'btn_later_click',
  btn_learnmore_click: 'btn_learnmore_click',
  btn_login_click: 'btn_login_click',
  btn_logout_click: 'btn_logout_click',
  btn_no_click: 'btn_no_click',
  btn_ok_click: 'btn_ok_click',
  btn_ok_show: 'btn_ok_show',
  btn_personality_click: 'btn_personality_click',
  btn_personality_swipe: 'btn_personality_swipe',
  btn_randomize_click: 'btn_randomize_click',
  btn_remove_click: 'btn_remove_click',
  btn_resume_click: 'btn_resume_click',
  btn_resume_show: 'btn_resume_show',
  btn_return_click: 'btn_return_click',
  btn_save_click: 'btn_save_click',
  btn_send_click: 'btn_send_click',
  btn_send_colorchange: 'btn_send_colorchange',
  btn_setting_click: 'btn_setting_click',
  btn_shot_click: 'btn_shot_click',
  btn_signup_click: 'btn_signup_click',
  btn_startplan_click: 'btn_startplan_click',
  btn_subscribe_click: 'btn_subscribe_click',
  btn_upgrade_click: 'btn_upgrade_click',
  btn_upgrade_show: 'btn_upgrade_show',
  btn_upgrage_click: 'btn_upgrage_click',
  btn_upgrage_show: 'btn_upgrage_show',
  btn_upload_faqs_click: 'btn_upload_faqs_click',
  btn_useservice_click: 'btn_useservice_click',
  btn_voice_click: 'btn_voice_click',
  btn_voice_swipe: 'btn_voice_swipe',
  btn_yes_click: 'btn_yes_click',
  card_aibot_show: 'card_aibot_show',
  card_authoravatarlist_click: 'card_authoravatarlist_click',
  card_authoravatarlist_show: 'card_authoravatarlist_show',
  card_authoravatarlist_swipe: 'card_authoravatarlist_swipe',
  card_avatar_click: 'card_avatar_click',
  card_avatar_swipe: 'card_avatar_swipe',
  card_continueediting_click: 'card_continueediting_click',
  card_continueediting_show: 'card_continueediting_show',
  card_createnewaibot_click: 'card_createnewaibot_click',
  card_createnewaibot_show: 'card_createnewaibot_show',
  card_editaibot_click: 'card_editaibot_click',
  card_editaibot_show: 'card_editaibot_show',
  card_faqs_click: 'card_faqs_click',
  card_faqs_show: 'card_faqs_show',
  card_knowledge_click: 'card_knowledge_click',
  card_suggest_click: 'card_suggest_click',
  card_suggest_swipe: 'card_suggest_swipe',
  icon_add_click: 'icon_add_click',
  icon_add_show: 'icon_add_show',
  icon_album_click: 'icon_album_click',
  icon_back_click: 'icon_back_click',
  icon_camera_click: 'icon_camera_click',
  icon_chatroom_click: 'icon_chatroom_click',
  icon_close_click: 'icon_close_click',
  icon_confirmage_click: 'icon_confirmage_click',
  icon_conversationstarters_close_click:
    'icon_conversationstarters_close_click',
  icon_edit_click: 'icon_edit_click',
  icon_export_click: 'icon_export_click',
  icon_info_click: 'icon_info_click',
  icon_info_faqs_click: 'icon_info_faqs_click',
  icon_knowledge_info_click: 'icon_knowledge_info_click',
  icon_menu_click: 'icon_menu_click',
  icon_mic_click: 'icon_mic_click',
  icon_moon_f_click: 'icon_moon_f_click',
  icon_moon_s_click: 'icon_moon_s_click',
  icon_more_click: 'icon_more_click',
  icon_mute_click: 'icon_mute_click',
  icon_myavatar_more_click: 'icon_myavatar_more_click',
  icon_output_click: 'icon_output_click',
  icon_question_click: 'icon_question_click',
  icon_records_click: 'icon_records_click',
  icon_send_click: 'icon_send_click',
  icon_switch_click: 'icon_switch_click',
  icon_unmute_click: 'icon_unmute_click',
  list_about_click: 'list_about_click',
  list_account_click: 'list_account_click',
  list_alertcreditcard_click: 'list_alertcreditcard_click',
  list_alertcreditcard_show: 'list_alertcreditcard_show',
  list_avatar_click: 'list_avatar_click',
  list_birthday_click: 'list_birthday_click',
  list_cancelplan_click: 'list_cancelplan_click',
  list_category_select_click: 'list_category_select_click',
  list_connectwith_click: 'list_connectwith_click',
  list_creditcard_click: 'list_creditcard_click',
  list_creditcard_default_click: 'list_creditcard_default_click',
  list_creditcard_error_click: 'list_creditcard_error_click',
  list_creditcard_error_show: 'list_creditcard_error_show',
  list_default_click: 'list_default_click',
  list_default_show: 'list_default_show',
  list_edit_click: 'list_edit_click',
  list_email_click: 'list_email_click',
  list_engagements_click: 'list_engagements_click',
  list_ethicalrules_click: 'list_ethicalrules_click',
  list_everyone_click: 'list_everyone_click',
  list_feedback_click: 'list_feedback_click',
  list_gender_select_click: 'list_gender_select_click',
  list_helpcenter_click: 'list_helpcenter_click',
  list_inviteonly_click: 'list_inviteonly_click',
  list_linkaccount_click: 'list_linkaccount_click',
  list_myaibot_click: 'list_myaibot_click',
  list_onlyme_click: 'list_onlyme_click',
  list_password_click: 'list_password_click',
  litst_payment_click: 'litst_payment_click',
  list_paymentmethod_click: 'list_paymentmethod_click',
  list_paypal_click: 'list_paypal_click',
  list_plans_click: 'list_plans_click',
  list_privacyterm_click: 'list_privacyterm_click',
  list_professionalbackground_click: 'list_professionalbackground_click',
  list_ratehumanate_click: 'list_ratehumanate_click',
  list_remove_click: 'list_remove_click',
  list_reportviolation_click: 'list_reportviolation_click',
  list_support_click: 'list_support_click',
  list_userfeedbacksupport_click: 'list_userfeedbacksupport_click',
  list_username_click: 'list_username_click',
  list_usersafetyprivacy_click: 'list_usersafetyprivacy_click',
  list_usertermsofservice_click: 'list_usertermsofservice_click',
  pic_aibot_click: 'pic_aibot_click',
  pic_aibotspicture_click: 'pic_aibotspicture_click',
  pic_cameraview_show: 'pic_cameraview_show',
  pic_edit_swipe: 'pic_edit_swipe',
  pic_headshot_click: 'pic_headshot_click',
  pic_media_click: 'pic_media_click',
  pic_media_show: 'pic_media_show',
  pic_photo_click: 'pic_photo_click',
  pic_photo_show: 'pic_photo_show',
  pic_spot_click: 'pic_spot_click',
  pic_spot_show: 'pic_spot_show',
  pic_url_click: 'pic_url_click',
  pic_url_show: 'pic_url_show',
  screen_swipe: 'screen_swipe',
  switchbtn_notifications_off_click: 'switchbtn_notifications_off_click',
  switchbtn_notifications_on_click: 'switchbtn_notifications_on_click',
  switchbtn_wifi_off_click: 'switchbtn_wifi_off_click',
  switchbtn_wifi_on_click: 'switchbtn_wifi_on_click',
  text_about_click: 'text_about_click',
  text_addmore_click: 'text_addmore_click',
  text_alert_show: 'text_alert_show',
  text_allowaccess_click: 'text_allowaccess_click',
  text_allowaccess_show: 'text_allowaccess_show',
  text_changeappearance_click: 'text_changeappearance_click',
  text_changepicture_click: 'text_changepicture_click',
  text_confirmagealert_show: 'text_confirmagealert_show',
  text_confirmnewpasswordalert_show: 'text_confirmnewpasswordalert_show',
  text_connecttogoogle_click: 'text_connecttogoogle_click',
  text_cookies_click: 'text_cookies_click',
  text_create_click: 'text_create_click',
  text_createfirstquestion_click: 'text_createfirstquestion_click',
  text_createfirstquestion_show: 'text_createfirstquestion_show',
  text_currentpasswordalert_show: 'text_currentpasswordalert_show',
  text_deleteaccount_click: 'text_deleteaccount_click',
  text_done_click: 'text_done_click',
  text_editaibotpicture_click: 'text_editaibotpicture_click',
  text_forgotpassword_click: 'text_forgotpassword_click',
  text_helpcenter_click: 'text_helpcenter_click',
  text_homepage_click: 'text_homepage_click',
  text_login_click: 'text_login_click',
  text_logout_click: 'text_logout_click',
  text_newpasswordalert_show: 'text_newpasswordalert_show',
  text_noresult_show: 'text_noresult_show',
  text_privacypolicy_click: 'text_privacypolicy_click',
  text_records_show: 'text_records_show',
  text_refresh_click: 'text_refresh_click',
  text_resume_click: 'text_resume_click',
  text_resume_show: 'text_resume_show',
  text_save_click: 'text_save_click',
  text_seemore_click: 'text_seemore_click',
  text_seemore_show: 'text_seemore_show',
  text_sendverificationemail_click: 'text_sendverificationemail_click',
  text_sendverificationemail_show: 'text_sendverificationemail_show',
  text_signup_click: 'text_signup_click',
  text_subscribe_click: 'text_subscribe_click',
  text_subscribe_show: 'text_subscribe_show',
  text_termsofservice_click: 'text_termsofservice_click',
  text_upgrade_click: 'text_upgrade_click',
  text_upgrade_show: 'text_upgrade_show',
  text_upgradeplan_click: 'text_upgradeplan_click',
  text_upgradeplan_show: 'text_upgradeplan_show',
  text_upgragde_click: 'text_upgragde_click',
  text_upgragde_show: 'text_upgragde_show',
  text_upload_click: 'text_upload_click',
  text_userrules_click: 'text_userrules_click',
  text_usertermsofservice_click: 'text_usertermsofservice_click',
  textbar_birthday_click: 'textbar_birthday_click',
  textbar_category_click: 'textbar_category_click',
  textbar_gender_click: 'textbar_gender_click',
  textbar_language_click: 'textbar_language_click',
  textfield_answer_click: 'textfield_answer_click',
  textfield_answer_typing: 'textfield_answer_typing',
  textfield_appearance_click: 'textfield_appearance_click',
  textfield_appearance_typing: 'textfield_appearance_typing',
  textfield_bio_click: 'textfield_bio_click',
  textfield_bio_typing: 'textfield_bio_typing',
  textfield_message_click: 'textfield_message_click',
  textfield_message_typing: 'textfield_message_typing',
  textfield_question_click: 'textfield_question_click',
  textfield_question_typing: 'textfield_question_typing',
  textfield_reason_click: 'textfield_reason_click',
  textfield_reason_typing: 'textfield_reason_typing',
  tip_engagement_click: 'tip_engagement_click',
  avatar_feed: 'avatar_feed',
  avatar_picture_copyright_error: 'avatarpicturecopyright_error',
  tip_state_credits_click: 'tip_state_credits_click',
  tip_state_warning_click: 'tip_state_warning_click',
  card_powerup_show: 'card_powerup_show',
  popup_insufficientcredits_show: 'popup_insufficientcredits_show',
  btn_purchasecredits_click: 'btn_purchasecredits_click',
  popup_avatarpicturecopyright_show: 'popup_avatarpicturecopyright_show',
  popup_paymentfailure_show: 'popup_paymentfailure_show',
  btn_submit_click: 'btn_submit_click',
  bar_phone_typing: 'bar_phone_typing',
  bar_phone_click: 'bar_phone_click',
  textbar_country_click: 'textbar_country_click',
  bar_firstname_click: 'bar_firstname_click',
  bar_firstname_typing: 'bar_firstname_typing',
  bar_lastname_click: 'bar_lastname_click',
  bar_lastname_typing: 'bar_lastname_typing',
  btn_purchase_click: 'btn_purchase_click',
  popup_addpaymentmethod_show: 'popup_addpaymentmethod_show',
  icon_download_click: 'icon_download_click',
  list_records_show: 'list_records_show',
  subscriptionplan_show: 'subscriptionplan_show',
  popup_subscriptionconsent_show: 'popup_subscriptionconsent_show',
}
